import React, {useState, useEffect} from 'react';

import PageContext from './pageContext';
import usePageProvider from './usePageProvider';

interface PageProviderProps {
	children: any;
	page: string;
	data: any;
	downloadLink: string;
	pageRelatedTo: string;
	showCheckboxToDownload: boolean;
	apkPromise: any;
	fallbackCase: boolean;
	gclidValue: string;
	andriodLinkRef: string;
	popupShow:boolean;
	mobileformShowRef?: boolean;
	gatsbyDownloadBtnEvent: string;
	userBucket:string;
}
const PageProvider: React.FC<PageProviderProps> = ({
	children,
	page,
	data,
	downloadLink,
	pageRelatedTo,
	showCheckboxToDownload,
	apkPromise,
	fallbackCase,
	gclidValue,
	andriodLinkRef,
	popupShow,
	gatsbyDownloadBtnEvent,
	userBucket
}) => {

	const [popupThankyouRef, setPopupThankyouRef] = useState<boolean>(false);
	const [mobileformRef, setMobileformRef] = useState<boolean>(false);
	const [popupShowRef, setPopupShowRef] = useState<boolean>(popupShow);
	const [preHandgestureLoader, setPreHandgestureLoader] = useState<boolean>(false);
	const [downloadClick, setDownloadClick] = useState<boolean>(false);
	const [playAudio, setPlayAudio] = useState(0);
	

	const { pageName } = usePageProvider({ page });
	return (
		<PageContext.Provider
			value={{
				page: pageName,
				data,
				downloadLink,
				pageRelatedTo,
				showCheckboxToDownload,
				apkPromise,
				fallbackCase,
				gclidValue,
				andriodLinkRef,
				popupShowRef,
				setPopupShowRef,
				popupThankyouRef,
				setPopupThankyouRef,
				mobileformRef,
				preHandgestureLoader,
				setPreHandgestureLoader,
				downloadClick,
				setDownloadClick,
				playAudio,
				setPlayAudio,
				setMobileformRef,
				gatsbyDownloadBtnEvent,
				userBucket
			}}>
			{children}
		</PageContext.Provider>
	);
};
export default PageProvider;
